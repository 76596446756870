#plan-error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    line-height: 30px;
    font-size: 2.2rem;
}

#plan-error-container svg {
    font-size: 64px;
    color: #000;
    margin-bottom: 20px
}

#plan-error-container strong {
    color: var(--color-theme-blue3);
}
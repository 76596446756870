#dashboardContainer {
    position: absolute;
    left: 50%;
    width: 65vw;
    margin-left: -32.5vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    border-radius: 10px;
}

#performanceContainer {
    display: flex;
}

#chartContainer {
    flex: 1;
    display: flex;
    height: auto;
    padding: 0px;
    flex-direction: column;

}

#chartContainer .navigator {
    display: flex;


}

#chartContainer .navigator .section {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
    transition-duration: 0.2s;
    cursor: pointer;
}

#chartContainer .navigator .activeSection {
    font-weight: bold;
    border-top: 4px solid #000;
}

#chartContainer .navigator .section:hover {
    background-color: #f1f1f1;
}

#chartContainer .navigator .section .title {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

#chartContainer .navigator .section .value {
    font-size: 2.8rem;
    font-family: Segoe UI;
    font-weight: normal;
}

#chartContainer .navigator .section .variation {
    font-size: 1.40rem;
    display: flex;
    align-items: center;
    font-family: segoe ui;
}

#chartContainer .navigator .section .positive {
    color: #08d008
}

#chartContainer .navigator .section .negative {
    color: rgb(165, 165, 165)
}

#chartContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #FFF;
}

.navigator .available {
    border-color: #000 !important;
}

.navigator .selling {
    border-color: var(--color-status-selling) !important;
}

.navigator .alert {
    border-color: var(--color-status-alert) !important;
}

.navigator .losing {
    border-color: var(--color-status-losing) !important;
}

.navigator .netshoes {
    border-color: var(--color-status-netshoes) !important;
}


#dashboardContainer #summaryContainer {
    position: initial;
    align-self: stretch;
    margin-left: 10px;
}

#dashboardContainer #summaryContainer .chartArea {

    margin: 40px 0px;
    width: 100%;
    height: 180px;

}

/* loading */

.loadingPerformance {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ;
}

.loadingPerformance img {
    width: 40px;
    height: 40xp;
}


/* select */

.selectDate {
    padding: 15px;
}


#newSalesContainer {
    flex: 1;
    margin-right: 5px;
}

#lostSalesContainer {
    flex: 1;
    margin-left: 5px;
}


.salesBigNumber {
    margin: 20px 0px;
    font-size: 6rem;
    font-family: segoe ui;
    display: flex;
    align-items: center;
}

.salesBigNumber span {
    margin-left: 15px;
}




/* LISTA DE PRODUTOS */

#viewProducts {
    position: fixed;
    width: 60vw;
    margin-left: -30vw;
    top: 30px;
    left: 50%;
    height: 90vh;
    overflow-y: auto;
}

#viewProducts .cell {
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    transition-duration: 0.2s;
    flex-direction: column;
    padding: 15px;
}
 
#viewProducts .chartContainer {
    padding-top: 20px;
    
} 

#viewProducts .cell:hover {
    background-color: rgb(242 248 255);
}

#viewProducts .productsList .section {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
}

#viewProducts .productsList .subSection {
    flex: 1;
    align-items: center;
    justify-content: center;

    text-align: center;
    flex-direction: column;

}

#viewProducts .productsList a {
    width: 65%;
    display: inline-block;
}

#viewProducts .productsList span {
    margin-top: 10px;
    font-size: 1.2rem;
    display: inline-block;
}

#viewProducts .productsList .sku {
    color: gray;
}
#accountContainer {
    width: 50vw;
    position: absolute;
    left: 50%;
    margin-left: -25vw;
    margin-bottom: 20px;
}

#accountContainer .informationContainer {
    background-color: #FFF;
    width: 100%;
    padding: 20px;
    font-size: 2rem;
    margin-bottom: 10px;
    border: 1px solid var(--color-border);
    border-radius: 10px;

}

#accountContainer .empty {
    color: gray;
    font-size: 1.3rem;
}

#accountContainer .content {
    display: flex;
    align-items:center
}

#accountContainer .content .information {
    flex:1;
    align-items:center
}

#accountContainer .editButtons{
    display:flex;
}

#accountContainer .editButtons .save{
    margin-right:5px;
}

#accountContainer .error{
    color:var(--color-fail);
    font-size:1.2rem;
    margin-top:10px;
}
.headerContainer {
    background-color: #FFF;
    flex: 1;
    height: 60px;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    -webkit-box-shadow: 0px 1px 4px 0px rgb(226, 226, 226);
    -moz-box-shadow: 0px 1px 4px 0px rgb(226, 226, 226);
    box-shadow: 0px 1px 4px 0px rgb(226, 226, 226);
    z-index: 4;
    display: flex;
    align-items: center;
}

.headerContainer .spot {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
}

#pageTitleSpot {
    position: absolute;
    left: 50%;
    margin-left: -100px;
    width: 200px;
    font-size: 14px;
    font-weight: bold;
}


#pageTitleSpot svg {
    margin-right: 15px;
}

.headerContainer .logo {
    color: #000;
    text-decoration: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}



.headerContainer #buttonSpot {
    position: absolute;
    right: 100px;
    display: flex;
    align-items: center;
}

.headerContainer #buttonSpot .spot {
    margin-right: 15px;
}



#userSpot {
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
}

#userSpot svg {
    margin-right: 10px;
}


#notificationsWindow {
    position: absolute;
    top: 40px;
    background-color: #FFF;
    border: 1px solid var(--color-border);

    width: 30vw;
    right: 0px;
    z-index: 5;
    border-radius: 10px;
}

#notificationsWindow li {
    padding: 15px;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    line-height: 20px;
    border-bottom: 1px solid var(--color-border);
    transition-duration: 0.2s;
    cursor: pointer;
}

#notificationsWindow li:hover {
    background-color: whitesmoke;
}

#notificationsWindow li .icon {
    margin-right: 20px;
}

#notificationsWindow li .type {
    font-weight: bold;
    margin-bottom: 5px;
}

#notificationsWindow .Novidade {
    color: green;
}

#unreadMessagesBox {
    position: absolute;
    top: 30px;
    right: 0px;
    width: 25vw;
    height: 60px;
    display: flex;
    font-weight: bold;
    border: 1px solid var(--color-border);
    font-size: 1.3rem;
    justify-content: center;
    align-items: center;
    background-color: rgb(227, 240, 255);
    box-shadow: 0px 0px 1px 1px var(--color-border);
    transition-duration: 0.2s;
}
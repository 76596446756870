
/* MODAL */

.modal {
    background-color: #FFF;
    z-index: 4;
    display: flex;
    flex-direction: column;
    max-height:90vh;
    overflow-y:auto;
    position:relative;
  
  }
  
  .modal .modalHeader {
    padding: 15px;
    display: flex;
    position:sticky;
    top:0px;
    background: #FFF;
    box-shadow:0px 0px 4px 1px var(--color-border);
    align-items: center;

  }
  
  
  .modal .modalHeader .title {
    flex: 1;
    display:flex;
    align-items: center;
  }
  
  .modalContent {
    flex: 1;
  }
  
  .labelSpot {
    margin-bottom: 10px;
  }
  
  
.modalContent .row{
    display:flex;
    border-bottom:1px solid #e1e1e1;
    align-items: center;
}

.modalContent .row .field{
    flex:1;
    padding:15px;
    font-weight:bold;
    font-size:1.3rem;
}

.modalContent .row .input{
    padding:15px;
    font-size:1.3rem;
    font-weight:normal;
    flex:1;
    width: 100%;
    display: flex;
    align-items: center;
}

.modalContent .row .input input{
  height:30px;
  width: 100%;
}

#editProductModal {
    width: 60vw;
    position: fixed;
    left: 50%;
    margin-left: -30vw;
    top: 70px;
    overflow-y: auto;
    max-height: 90vh;

}
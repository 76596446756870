

.feedback {


    button:disabled{
        opacity:0.7;
        background-color: gray;
    }
 
    -webkit-user-select: none;
    user-select: none;
    padding: 20px;

    &__buttons{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }


    &__commentary {
        width: 80%;
        display: flex;
        margin: 20px auto;
        text-overflow: ellipsis;
        overflow: scroll;
        height: 100px;
    }

    &__satisfaction {

        display: flex;
        flex-direction: column;
    
        margin-bottom: 20px;
    

        &__header {
            margin-bottom: 20px;

            h3{
                font-size: 2rem;
            }

           
            &__sub{
                margin-top: 10px;
                color:gray;
                font-size: 1.6rem;
            }

        }

        &__options {

            display: flex;
            justify-content: space-between;


            &__option {

                -webkit-user-select: none;
                user-select: none;
                transition: 0.2s;
                border-radius: 10px;
                font-size: 1.6rem;
                display: flex;
                flex-direction: column;
                text-align: center;
                border: 1px solid #e1e1e1;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
          
                background-color: #FFF;

                &:last-child {
                    margin-right: 0px;
                }

            }

            &__option_selected {
                background-color: var(--color-theme-blue3);
                -webkit-user-select: none;
                user-select: none;
                transition: 0.2s;
                border-radius: 10px;
                font-size: 1.6rem;
                display: flex;
                flex-direction: column;
                text-align: center;
                border: 1px solid #e1e1e1;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
             
                color: #FFF;
            }

            h4{
                margin-top:5px;
            }

        }
    }

    &__feedback_sent{
        padding: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 40px;

        img{
            width: 200px;
            height:200px;
            object-fit: contain;
        }

        span{
            color:gray;
        }
    }
}

.comment {

    &__input {
        width: 100%;
        display: flex;
        margin: 20px auto;
        text-overflow: ellipsis;
        overflow: visible;
        flex-wrap: wrap;
        height: 150px;
        max-height: 150px;
        padding: 10px;
        line-height: 20px;
        font-family: Roboto;
        letter-spacing: 1px;
        border-radius: 10px;
        resize: vertical;
        // outline: solid $button-blue 2px;
    }

}
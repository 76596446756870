/* CONFIRM BOX*/

.confirm-box {

    z-index: 5;
    background: white;
    width: 30vw;
    font-size: 13px;
    position: fixed;
    left: 50%;
    margin-left: -15vw;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #e1e1e1;
    top: 100px;
    border-radius:5px;
  
  }
  
  
  .confirm-box .title {
    font-weight: bold;
    color: black;
    padding: 15px 15px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    position: relative;
    align-items: center;
  
  }
  
  .confirm-box .title div {
    height: 100%;
  }
  
  
  
  .confirm-box .title .cancel {
  
    position: absolute;
    right: 15px;
  
  }
  
  
  
  .confirm-box .title .cancel button {
    border: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    transition-duration: 0.2s;
    background-color: transparent;
    display:flex;
    align-items:center;
    justify-content: flex-end;
  }
  .confirm-box .title .cancel button:hover{
      opacity:1;
  }

  
  .confirm-box .text {
  
    padding: 10px 15px;
    width: 100%;
  
  
  
  }
  
  .confirm-box .buttons {
  
  
  
    padding: 10px 15px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  
  
  }
  
  .confirm-box .buttons button {
    width: 6vw;
    margin-right: 5px;
  }
  
  
  .confirmation-overlay {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #0000007d;
    z-index: 3;
    display: block;
  
  }
  
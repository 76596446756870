#notFoundContainer{
    position:absolute;
    left:50%;
    width:50vw;
    margin-left:-25vw;
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    flex:1;
    height:80%;

}

.notFoundTitle{
    font-size:32px;
    margin-top:20px;
    margin-bottom:15px;
    font-weight: bold;
    font-family:segoe ui light;
}

#notFoundContainer .text{
    font-size:13px;
    color:gray;
    margin-bottom:20px;
}
#supportContainer{
    position:absolute;
    left:50%;
    width:50vw;
    margin-left:-25vw;
    height:50vh;

}

#questionsList  li{
    font-size:1.4rem;
    padding:40px 15px;
    border-bottom:1px solid #e1e1e1;
    margin-bottom:10px;
    cursor:pointer;
}

#questionsList .label{
    margin-bottom:10px;
}

#questionsList li:hover{
    background-color: whitesmoke;
}


.questionHeader{
    display:flex;
    align-items:center;
    font-size:1.8rem;
    margin-bottom:20px;
    font-weight:bold;
}

.questionHeader button{
    margin-right:10px;
}

.questionContent ul{
    padding:20px;
    list-style: decimal;
}

.questionContainer li{
    line-height:25px;
    font-size:1.5rem;
    margin-bottom:40px;
}

.questionContainer li img{
    width:100%;
    height:100%;
    object-fit: contain;
    border:1px solid var(--color-border);
    box-shadow:0px 0px 2px 1px var(--color-border);
    margin-top:20px;
}

.questionContainer .box{
    padding:10px;
    border:1px solid var(--color-border);
    box-shadow:0px 0px 1px 1px var(--color-border);
    margin-top:10px;
    background-color:var(--color-theme-blue2);
    color:#FFF
}
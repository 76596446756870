#productsContainer {
    width: 90vw;
    margin-bottom: 80px;
    background-color: #FFF;
    border: 1px solid #e1e1e1;
    border-radius:10px;
}


.productsContent {

    -webkit-box-shadow: 0px 1px 1px 0px rgba(196, 196, 196, 1);
    -moz-box-shadow: 0px 1px 1px 0px rgba(196, 196, 196, 1);
    box-shadow: 0px 1px 1px 0px rgba(196, 196, 196, 1);

}

#productsContainer #products-header{
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e1e1e1;
    position: sticky;
    top:61px;
    background-color: #FFF;
    z-index: 2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

#productsContainer #products-header .title{
    flex:1;
}





/* HEADER CELL */


#productsContainer .headerCell {

    padding: 1rem;
    background-color: #FFF;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    transition-duration: 0.2s;
    cursor: pointer;
    letter-spacing: 0.25px;
    height: 60px;
    font-size: 1.3rem;
    color: rgb(87, 87, 87);
    font-weight: 500;
    position: sticky;
    top:121px;
    background-color: #FFF;
    z-index: 2;
    border-top:1px solid #e1e1e1;

}


#productsContainer .headerCell .arrow {

    align-items: center;
    display: flex;
    justify-content: center;

}


#productsContainer  .index {
    width: 80px;
    align-items: center;
    display: flex;
    justify-content: center;

}

#productsContainer .headerCell .edit {
    width: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 10px;
}

#productsContainer .headerCell .section {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

}

#productsContainer .section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* max-width: 6vw; */
    transition-duration: 0.2s;
    text-align: left;
    flex-direction: column;
    flex: 1;
    padding: 15px;
}

#productsContainer .section-big {
    min-width: 26vw;
}


#productsContainer .section-small {
    width: 6vw;
}


#productsContainer .headerCell .section .order {
    display: flex;
    align-items: center;
}





/* CELL */





#productsContainer .cell {
    font-size: 1.4rem;

    border-bottom: 1px solid #e1e1e1;
    display: flex;
    transition-duration: 0.2s;
    cursor: pointer;
    line-height: 22px;
    position: relative;

    font-weight: 500;


}

#productsContainer .cell:hover {
    background-color: whitesmoke;
}




#productsContainer .cell .edit {
    width: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 20px;
}




#productsContainer .cell .arrow {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 20px;
}


#productsContainer .cell .label {
    color: rgb(87, 87, 87);
    font-size: 1.1rem;
    font-weight: 500;
}







/* EDITAR PRODUTOS */

.buttonSpot {
    margin-right: 10px;
}


/* PESQUISA */

#productsContainer .search {
    background-color: #FFF;
    display: flex;
    align-items: center;
    outline: none;
    border: 1px solid #e1e1e1;
}



#productsContainer .status select {
    margin-left: 15px;
    margin-right: 15px;
}



/* Seller Links */

#productsContainer .cell .selling {
    color: var(--color-status-selling);
    font-weight: 500;
}

#productsContainer .cell .alert {
    color: var(--color-status-alert);
    font-weight: 500;
}




#productsContainer .cell .losing {
    color: var(--color-status-losing);
    font-weight: 500;
}

#productsContainer .cell .netshoes {
    background-color: var(--color-status-netshoes);
    color: white;
    padding: 0px 4px;
    border-radius: 5px;
    font-weight: 500;
}




/*  detalhes */

#productsContainer .cell .product-sku {
    display: flex;
    color: rgb(87, 87, 87);
    font-weight: normal;
    font-size: 1.3rem;
  
}

#productsContainer .cell .product-sku .division{
    margin-left:5px; margin-right:5px;
}

#productsContainer .cell .markupDifference {
    font-size: 1.2rem;
}

#productsContainer .cell .redLabel {
    color: var(--color-status-losing);
    font-size: 1.2rem;
}

#productsContainer .cell .greenLabel {
    color: var(--color-status-selling);
    font-size: 1.2rem;
}

#productsContainer .cell .blueLabel {
    color: rgb(61, 131, 210);
    font-size: 1.2rem;
}

#productsContainer .cell .orangeLabel {
    color: var(--color-status-alert);
    font-size: 1.2rem;
}

#productsContainer .cell .market-difference-percent {
    font-weight: 500;
    font-size: 1.2rem;
    margin-left: 5px;
}

#productsContainer .cell .grayLabel {
    color: var(--color-status-unknown);
    font-size: 1.2rem;
}

#productsContainer .cell .seller {
    display: flex;
    align-items: center;
    font-size: 13px;
}





.clock {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: gray;
}

.clock svg {
    margin-right: 3px;
}


/* paginação */


#productsContainer .pagination {
    flex: 1;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #FFF;
}

#productsContainer .pagination span {
    font-size:1.4rem;
    margin-right:10px;
}

#productsContainer .pagination select {

    margin-right:10px;
}

/* window */

#productsContainer .window {
    background-color: #FFF;
    padding: 10px;
    border: 1px solid var(--color-border);
    position: absolute;
  
    width: 200px;
    font-size: 1.2rem;
    height: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 20px;

}

#productsContainer .windowRow {
    display: flex;
}

#productsContainer .window .windowTitle {
    flex: 1;
    font-weight: bold;
    text-align: left;
    color: var(--color-text-label);
}

#productsContainer .window .windowValue {
    text-align: right;
}


@media screen and (max-width:1100px) {
    #stock {
        display: none !important;
    }

    #markup {
        display: none !important;
    }
}


.competition1 {
    font-weight: normal;
    font-size: 1.3rem;
}

.competition2 {
    font-weight: normal;
    font-size: 1.3rem;
    color: #000;
}


.medCompetition {
    display: flex;
    font-weight: normal;
    align-items: center;
}

.medCompetition svg {
    margin-right: 5px;
}

.highCompetition {
    display: flex;
    font-weight: normal;
    align-items: center;
}

.highCompetition svg {
    margin-right: 5px;
}

/* .competitionBadge{
    position:absolute;
    right:0px;
    top:0px;
    height:10px;
    width:10px;
    display:flex;
    align-items: center;
    justify-content: center;
    color:#FFF;
    font-size:1.3rem;
    padding:0px 1px;
} */


.custom-added-badge {
    background-color: rgb(0, 142, 255);
    font-size: 1.1rem;
    color: #FFF;
    height: 15px;
    line-height: 15px;
    border-top-right-radius: 4px;
    padding: 0px 8px;
    position: absolute;
    left: 0px;
    top: 0px;
    border-bottom-right-radius: 4px;
}

#lateral-product-view {
    position: fixed;
    width: 33vw;
    right: -29vw;
    background-color: #FFF;
    height: 100%;
    top: 0px;
    box-shadow: -3px 0px 20px 0px #cfcfcf;
    transition-duration: 0.1s;
    z-index: 5;
    overflow-y: auto;
    font-size: 1.4rem;
    line-height: 20px;

}

#lateral-product-view-overlay {
    background-color: rgba(216, 216, 216, 0.178);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 2;
}


#lateral-product-view .section {
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;
}

.productActive {
    background-color: rgb(230, 230, 230) !important;
}



.lateral-product-view-status-span-selling {
    color: var(--color-status-selling) !important;
}

.lateral-product-view-status-span-alert {
    color: var(--color-status-alert) !important;
}

.lateral-product-view-status-span-losing {
    color: var(--color-status-losing) !important
}

#lateral-product-view .product-title {
    display: flex;
}

#lateral-product-view .product-title h3 {
    font-size: 14px;
    font-weight: bold;

}

#lateral-product-view .product-title span {

    color: gray;
}

#lateral-product-view .product-title .actions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #e1e1e1;
}


#lateral-product-view .section-row {
    display: flex;
    align-items: center;
}

#lateral-product-view h2 {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500;
}

#lateral-product-view h2 svg {
    margin-right: 10px;
}

.sellers-list {
    /* overflow-y: auto;
    max-height: 250px; */
}

.sellers-list-item {
    padding: 10px 0px;
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;


}


.sellers-list-item-section {
    flex: 1;

    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.3rem;
}

.has-hover:hover {
    background-color: #eeeeee;
    transition-duration: 0.2s
}

.bottom-section {
    /* position: absolute;
    bottom: 0px; */
}

.suggested-price {
    color: green;
    font-weight: 500;
}

.suggested-price-explanation {
    color: gray;
}

.not-available-plan {
    color: var(--color-theme-blue3);
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-weight: 500;
}

.not-available-plan svg {
    margin-right: 7px;
}


.not-available-plan-gray {
    color: rgb(59, 59, 59);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}
#dialogContainer {
    position: fixed;
    bottom: 20px;
    left: 70px;
    width: 25vw;
    height:auto;
    z-index: 3;
    padding: 15px;
    font-weight:normal;
    font-size:1.3rem;
    line-height:25px;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content: center;
    color:#FFF;
    font-family:Roboto;
}

#dialogContainer svg{
    margin-right:10px;
    width:18px;
    height:18px;
}

#dialogContainer div{
    display:flex;
    align-items:center;
}

.success{
    background-color:var(--color-success);
}


.fail{
    background-color:var(--color-fail);
}

.notice{
    background-color:var(--color-theme-blue3);
}

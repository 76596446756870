.menuContainer {
    position: fixed;
    top: 0px;
    width: 16vw;
    background-color:#272c2e;
    height: 100%;
    border-right: 1px solid #e1e1e1;
    z-index: 2;
    box-shadow: 0px 0px 3px 1px #e1e1e1;
    transition-duration: 0.4s;
    z-index:4;

}

.menuContainer ul {
    list-style: none;
    list-style-type: none;
    margin: 0px;
    padding: 0px;


}


.menuContainer .item {
    padding: 15px;
    font-size: 13px;
    cursor: pointer;
    font-weight: normal;
    color:#FFF;
    display: flex;
    align-items: center;
}



.menuContainer .item div {
    height: 100%;
    display: flex;
    align-items: center;
}

.menuContainer .item .icon {
    margin-right: 20px;
}

.menuContainer .item:hover {
    background-color: #30373a;
}

.menuContainer .activeItem {
    background-color: #30373a;
    color:#FFF;
    padding: 15px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;

}

.menuContainer .activeItem .icon {
    margin-right: 20px;
}

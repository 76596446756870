#manageContainer {
    width: 50vw;
    position: absolute;
    left: 50%;
    margin-left: -25vw;
}



#manageContainer .cell {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: #FFF;
    padding: 15px;
    border: 1px solid var(--color-border);
    transition-duration: 0.2s;
    cursor: pointer;
    line-height: 20px;
    border-radius: 10px;
}

#manageContainer .cell:hover {

    background-color: whitesmoke;

}

#manageContainer .cell .icon {
    margin-right: 15px;
}

#manageContainer .cell .title {
    font-size: 1.6rem;

}

#manageContainer .cell .subtitle {
    font-size: 1.2rem;
    color: gray;
}

#manageContainer .cell .text {
    display: flex;
    justify-content: center;
    flex-direction: column;
}





/* IMPORT DATA */

#importerModal {
    width: 50vw;
    position: fixed;
    top: 70px;
    left: 50%;
    margin-left: -25vw;
    height: auto;
    z-index: 5;
}

#importerContent {
    display: flex;
    flex-direction: column;
}

#importerContent .cell {
    display: flex;
    padding: 15px;
    font-size: 1.3rem;
    border-bottom: 1px solid var(--color-border);

}

#importerContent .cell .spreadsheetName {
    flex: 1;
    font-weight: bold;
    color: var(--color-text-label);
    display: flex;
    align-items: center;

}

#importerContent .cell .spreadsheetInput {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.3rem;
    color: var(--color-text-label);
}

#importerContent .cell .spreadsheetInput input {
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-border);
    padding: 8px 5px;
}

#importerContent .importerButton {
    position: relative;
    width: 100%;
}

#importerContent .importerButton button {
    width: 100%;

}


#importerContent .importerButton input {
    position: absolute;
    opacity: 0;
}

#importerContent .importerButton input:hover+button {
    background-color: rgb(24, 92, 170);
}

#importerContent .spreadsheetSubmit {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

}

#importerContent .spreadsheetError {
    flex: 1;
    color: var(--color-fail);
    display: flex;
    align-items: center;
}

#importerContent .importLoading {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#importerContent .importLoading img {
    width: 40px;
    height: 40px;
}


/* EXPORT PRICES */

#addProductModal {
    width: 60vw;
    position: fixed;
    top: 70px;
    left: 50%;
    margin-left: -30vw;
    height: auto;
    z-index: 4;
    height: 85vh;
    display: flex;
}

#addProductModal .modalContent {}

#addProductModal ul,
form {
    width: 100%;
}



#addProductModal li {
    padding: 15px;
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 15px;
}

#addProductModal .input {
    display: flex;
    flex: 1;
}

#addProductModal .input div {
    flex: 1;
}

#addProductModal {
    display: flex;
    flex-direction: column;
    flex: 1;
}

#addProductModal .input .title {
    margin-bottom: 10px;
    font-size: 1.4rem;
    font-weight: bold;

}

#addProductModal .input input,
#addProductModal .input select {
    background-color: whitesmoke;
    padding: 8px;
    border: 0;
    width: 100%;

    font-size: 1.4rem;
}



#exportPricesContent {
    display: flex;
}

#exportPricesContent .contentSection {
    padding: 15px;
    font-size: 1.3rem;
    line-height: 25px;
    display: flex;
    flex-direction: column;
    min-width: 4vw;
}



/* HISTORIC CELL */

#exportPricesContent .historicCell {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--color-border);
    width: 100%;
    transition-duration: 0.2s;
    cursor: pointer;
    align-items: center;
    line-height: 25px;
}




#exportPricesContent .historicCell .section {
    flex-direction: column;
    width: 180px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#exportPricesContent .section .subinformation {
    font-size: 1.2rem;
    color: gray;
}


#exportPricesContent .variation {
    flex-direction: row !important;
}

#exportPricesContent .variation svg {
    margin: 0 15px;
}

#exportPricesContent .historicCell:hover {
    background-color: whitesmoke;
}


#exportPricesContent svg {
    margin-right: 3px;
}



/* CONTENT SECTION */

#exportPricesContent .title {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

#exportPricesContent .title div {
    display: flex;
    align-items: center;
}

#exportPricesContent .list {
    width: 100%;
    height: 65vh;
    overflow-y: auto;
}


/* HEADER CELL */

#exportPricesContent .historicHeaderCell {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--color-border);
    width: 100%;
    transition-duration: 0.2s;
    cursor: pointer;
    align-items: center;
}

#exportPricesContent .historicHeaderCell .section {
    flex-direction: column;
    width: 180px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.historicItemLoading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.historicItemLoading img {
    width: 40px;
    height: 40px;
}


/* GERADOR */
#generatorSection {
    display: flex;
    flex: 1;
}

#generatorSection .filterCell {
    margin-bottom: 20px;
}

#generatorSection .input {
    border: 1px solid var(--color-border);
    padding: 5px;
    color: black;
    font-weight: bold;
    font-size: 1.2rem;
    position: relative;
}

#generatorSection .input input {
    border: 0;
    outline: none;
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 5px;
    width: 90%;
}

#generatorSection .checkbox {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--color-text-label);
    display: flex;
    align-items: center;

}

#generatorSection .checkbox input {
    margin-right: 5px;
}

.error {
    color: var(--color-fail);
    font-weight: bold;
    font-size: 1.2rem;
}



/* PRODUCT CELL */

#generatorSection .productCell {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--color-border);
    width: 100%;
    transition-duration: 0.2s;
    cursor: pointer;
    align-items: center;
    line-height: 25px;
}

#generatorSection .productHeaderCell {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--color-border);
    width: 100%;
    transition-duration: 0.2s;
    cursor: pointer;
    align-items: center;
}

#generatorSection .productHeaderCell .section {
    flex-direction: column;
    width: 7vw;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
}

.productCell .section {
    flex-direction: column;
    width: 7vw;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.productCell .section .subinformation {
    color: gray;
    font-size: 1.2rem;
}

.productCell .alert {
    color: var(--color-status-alert);
    font-weight: bold;
}

.productCell .losing {
    color: var(--color-status-losing);
    font-weight: bold;
}

.productCell .newPrice {
    color: rgb(61, 131, 210);
    font-weight: bold;
}

.selected {
    background-color: rgb(238 255 237);
}

.noResults {
    font-weight: bold;
    padding: 10px;
    font-size: 1.2rem;
}


/* MARKUP */

#markupContainer {
    width: 60vw;
    position: fixed;
    top: 70px;
    left: 50%;
    margin-left: -30vw;
    height: auto;
    z-index: 4;
    height: 85vh;
    overflow-y: hidden;
}

#markupContainer ul {
    flex: 1;
    overflow-y: auto;
    height: 71vh;
}


#markupContainer ul li {
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
}


#markupContainer ul li input {
    width: 100%;
    height: 100%;
    border: 0px;
    background-color: whitesmoke;
    padding: 10px;
}

#markupContainer ul li .section {
    flex: 1;
    display: flex;
    align-items: center;
}

#markupContainer .bottomRow {
    padding: 10px;
    background-color: #FFF;
    position: sticky;
    top: 49px;
    border-top: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}



#markupContainer .empty {
    color: gray;
    font-size: 1.3rem;
}

#markupContainer .search {
    margin-right: 10px;
    background: whitesmoke;
    padding: 2px;

}

#markupContainer .search svg {
    width: 14px;
    height: 14px;

}

#success-layer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    z-index: 1;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 2rem;

}

#success-layer svg {
    color: var(--color-success);
    margin-right: 10px;
    width: 24px;
    height: 24px;
}
#optimizeContainer {
    width: 63vw;
    margin-left: -31.5vw;
    position: absolute;
    left: 50%;
    display: flex;
    flex-direction: column;
}

#pricesOpporntunities {
    flex: 1;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    height: 200px;
}

#optimizeOpportunities {
    flex: 1;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    height: 200px;
}

#optimizeContainer .bigNumber {

    font-size: 6rem;
    font-family: segoe ui;
    display: flex;
    align-items: center;
    flex: 1;
}



#optimizeContainer .bigNumber span {
    margin-left: 15px;
}

#optimizeContainer .historic {
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    display: flex;
}

.bigNumber h3 {
    font-size: 1.6rem;
}

#viewOpportunities {
    width: 85vw;
    top: 30px;
    margin-left: -42.5vw;
    position: fixed;
    left: 50%;
    overflow-y: hidden;
}

#viewOpportunities ul {
    max-height: 75vh;
    flex: 1;
    overflow-y: auto;
}

#viewOpportunities a {
    font-weight: 500;
}


#viewOpportunities ul li {
    border-bottom: 1px solid #e1e1e1;
    transition-duration: 0.2s;
    cursor: pointer;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    position: relative;

}

#viewOpportunities ul .hover:hover {
    background-color: whitesmoke;
}

#viewOpportunities ul .title {
    align-items: center;
    text-align: center;
}

#viewOpportunities ul .reoptimize {
    border-left: 6px solid orange;
}

#viewOpportunities .data .section,
#optimizedProductsContainer .section {
    flex: 1;
    display: flex;

    align-items: center;
    text-align: center;
    justify-content: center;
}

#viewOpportunities .data .subSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    text-align: left;
    justify-content: center;
    line-height: 22px;
    font-weight: 500;
}

#optimizedProductsContainer .subSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    text-align: left;
    justify-content: center;
    line-height: 25px;
    font-weight: normal;
}

#viewOpportunities .arrowSection {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#viewOpportunities .arrowSection svg {
    width: 13px;
    height: 13px;
}


#viewOpportunities .data .section .sku {
    color: var(--color-text-label);

}

#viewOpportunities .data .section span {

    font-size: 1.3rem;
    font-weight: normal;
    letter-spacing: 0.25px;
}


#viewOpportunities .data .section .losing {
    color: var(--color-status-losing);
    font-weight: bold;
}

#viewOpportunities .data .section .netshoes {
    color: var(--color-status-netshoes);
    font-weight: bold;
}


#viewOpportunities .newDifference {
    color: rgb(61, 131, 210);
}


/* MODAL HEADER */


#viewOpportunities .modalHeader {
    box-shadow: 0px 0px 1px 1px var(--color-border);
}

#viewOpportunities .modalHeader button {
    flex: 1;

    background: #FFF;
    font-weight: bold;
    outline: none;

}

#viewOpportunities .modalHeader .activeTab {
    border-bottom: 4px solid rgb(61, 131, 210);
}


/* SELECT AND DOWNLOAD ROW */

#viewOpportunities .bottomRow {
    padding: 10px;
    background-color: #FFF;
    position: sticky;
    top: 49px;
    border-top: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
}

#viewOpportunities .bottomRow select {
    margin-left: 5px;
}



.indexSection {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    flex-direction: column;
}

.data {
    display: flex;
}

#viewOpportunities .data .section .historicBtn {
    margin-top: 10px;
}

#optimizeContainer .chartContainer {

    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    padding: 20px;
    border-top: 1px solid #e1e1e1;
}


/* earned */

#earnedContainer {
    flex: 1;
    margin-bottom: 10px;
    height: 200px;
    display: flex;
    padding: 0px;

}

#earnedContainer .earnedSection {
    flex: 1;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    padding: 15px;
    flex-direction: column;
}

#earnedContainer .title {
    display: flex;
    align-items: center;
}

#earnedContainer .mediumNumber svg {
    margin-left: 5px;
}

#earnedContainer h3 {
    display: inline-block;
    font-size: 1.5rem;

}

#earnedContainer .mediumNumber {
    color: #51CB20;
    font-size: 4rem;
    font-weight: normal;
    font-family: segoe ui;
    margin-top: 10px;
    display: flex;
    align-items: center;
}



#optimizedProductsContainer {
    padding: 0px;
    margin-bottom: 40px;
    border-radius: 10px;
}

#optimizedProductsContainer li {

    font-size: 1.3rem;
    display: flex;
    border-bottom: 1px solid var(--color-border);
    transition-duration: 0.2s;
    cursor: pointer;
}

#optimizedProductsContainer li a {
    width: 100%;

}


#optimizedProductsContainer li .data .section {
    flex: 1;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    line-height: 25px;
}



#optimizedProductsContainer .cell {
    display: flex;
    flex-direction: column;
}

#optimizedProductsContainer .cell:hover {
    background-color: whitesmoke;
}

#optimizedProductsContainer .cell .product {
    display: flex;
}

#optimizedProductsContainer h4 {
    font-size: 1.2rem;
}

#optimizedProductsContainer .sku {
    color: gray;
}

#optimizedProductsContainer .index {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#optimizedProductsContainer .index button svg {
    margin-top: 10px;
    width: 18px;
    height: 18px;
}



#optimizedProductsContainer .empty {
    padding: 15px;
    font-size: 1.3rem;
    color: gray;
}

#updatePrices {
    display: flex;
}


#updatePrices #lastUpdate {
    display: flex;
    flex: 1;
    font-size: 1.2rem;
    align-items: center;
    color: gray;
}

#updatePricesInput {
    position: relative;
    margin-left: 5px;
}

#updatePricesSubmit {
    margin-left: 5px;
}

#updatePrices input[type='file'] {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
}

#updatePrices input[type='file']:hover~button {
    background-color: rgb(24, 92, 170);
}

.earned {
    color: #51CB20;
    margin-left: 5px;
}
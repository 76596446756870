#summaryContainer {
    margin-left: 20px;
    width: 18vw;
    position: sticky;
    top: 80px;
    align-self: start;
    font-size:1.3rem;
    display:flex;
    flex-direction: column;
}



.summaryList{
    display:flex;
    flex-direction: column;

    margin-top:20px;
    line-height:30px;
}


.summaryRow{
    display:flex;
}

.rowTitle{
    flex:1;
    display:flex;
    align-items:center;

    
}

.rowTitle svg{
    margin-right:10px;
}

.summaryList a span{
    /* color:gray; */
    font-size:11px;
    font-weight: 500;
    margin-left:5px;
}

.summaryList a{
    font-weight:normal;
}

.summaryList .total a{
    color:#000;
}

.summaryList .selling, .selling a{
    color: var(--color-status-selling);
}

.summaryList .alert, .alert a{
    color: var(--color-status-alert);
}

.summaryList .losing, .losing a{
    color: var(--color-status-losing);
}

.summaryList .netshoes, .netshoes a{
    color: var(--color-status-netshoes);
}

.summaryList .unknown, .unknown a{
    color: var(--color-status-unknown);
}

.summaryList .unavailable, .unavailable a{
    color: var(--color-status-unavailable);
}   


.loadingSummary{
    flex:1;
    display:flex;
    align-items:center;
    justify-content: center;
}


.chartArea{
    width:100%;
    height:150px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom:0px;
    background-color: #FFF;
}
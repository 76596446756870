#reportContainer {
    width: 55vw;
    margin-left: -27.5vw;
    position: absolute;
    left: 50%;
}

#brandQuantityContainer {
    height: auto;

}

#brandQuantityContainer .navigator {
    display: flex;
    user-select: none;
}

#brandQuantityContainer .navigator .item {
    display: flex;
    flex: 1;
    padding: 20px;
    align-items: flex-start;
    justify-content: center;
    font-size: 1.4rem;
    flex-direction: column;
    transition-duration: 0.2s;
    cursor: pointer;
    max-width: 200px;
    user-select: none;
}

#brandQuantityContainer .navigator .arrow {
    justify-content: center;
    align-items: center;
    max-width: 50px;
}

#brandQuantityContainer .navigator .item:hover {
    background-color: #F1F1F1;
}

#brandQuantityContainer .navigator .item .number {
    font-size: 2.8rem;
    font-family: segoe ui;
    font-weight: normal;
}

#brandQuantityContainer .navigator .title {
    margin-bottom: 10px;
}


#brandQuantityContainer .navigator .activeItem {
    /* border-top:3px solid rgb(61, 131, 210); */
    font-weight: bold;
    background-color: rgb(61, 131, 210);
    color: #FFF;
}


#brandQuantityContainer .navigator .activeItem:hover {
    background-color: rgb(61, 131, 210);
}



/* ADDED PRODUCTS */

#addedProductsContainer {
    margin-top: 20px;

}


/* Competição */

#competitionContainer {
    margin-top: 20px;
}

#competitionContainer .container {
    padding: 0px;
    display: flex;
}

#competitionContainer .competitionSummaryList {
    display: flex;
    flex-direction: column;
    flex: 1;
    
}

#competitionContainer .competitionSummaryList .competitionSummaryListItem {
    padding-left: 20px;
    border-left-width: 6px;
    border-left-style: solid;
    flex: 1;
    padding: 20px;
    display: flex;
    cursor: pointer;
    border-bottom:1px solid #e1e1e1;
    transition-duration: 0.2s;
}

#competitionContainer .competitionSummaryList .competitionSummaryListItem:hover {
    background-color:#F1F1F1
}

#competitionContainer .competitionSummaryList .competitionSummaryListItem .number {
    display: flex;
    font-size: 2.8rem;
    align-items: center;
    font-family: segoe ui;
}

#competitionContainer .competitionSummaryList .competitionSummaryListItem span {
    font-size: 1.5rem;
    margin-left: 15px;
}

#competitionContainer .competitionSummaryList .high {
    border-left-color: #002fff;
}

#competitionContainer .competitionSummaryList .medium {
    border-left-color: #5dbcfc
}

#competitionContainer .competitionSummaryList .low {
    border-left-color: #b7dfff
}

#competitionContainer .competitionSummaryList .unknown {
    border-left-color: #e1e1e1
}

#competitionContainer .competitionSummaryList .num{
    display: flex;
 
}


#competitionContainer .chart {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Roboto;
}

:root {
  font-size: 60%;
  --color-background: #f3f3f3;
  --color-status-netshoes: purple;
  --color-status-selling: rgb(6, 179, 6);
  --color-status-alert: orange;   
  --color-status-losing: rgb(233, 4, 4);
  --color-status-unavailable: rgb(63, 63, 63);
  --color-status-unknown: gray;
  --color-text-label: rgb(49, 49, 49);
  --color-theme-blue1: #145374;
  --color-theme-blue2: rgb(61, 131, 210);
  --color-theme-blue3: #008eff;
  --color-border: #e1e1e1;
  --color-success: rgb(8, 202, 8);
  --color-fail: rgb(241, 63, 63);
  --color-theme-gray1: #474747;
}

/* default css */


body,
input,
button {

  text-rendering: geometricPrecision;
}

body {
  background-color: var(--color-background);
}

h3 {
  margin: 0px;
  padding: 0px;
}

label {
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  font-weight: bolder;
  color: var(--color-text-label);
}

ul {
  list-style: none;
  list-style-type: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


a {
  text-decoration: none;
  font-weight: normal;
  transition-duration: 0.2s;
  color: #000;
}

a:hover {
  text-decoration: underline;
}

.md-mb {
  margin-bottom: 20px;
}

.sm-mb {
  margin-bottom: 5px;
}

.nopadding {
  padding: 0px !important;
}

select {
  padding: 8px;
  font-size: 1.4rem;
}


/* container */

.container {
  background-color: #FFF;
  padding: 15px;
  border: 1px solid var(--color-border);
  /* -webkit-box-shadow: 0px 1px 1px 0px rgba(196, 196, 196, 1);
  -moz-box-shadow: 0px 1px 1px 0px rgba(196, 196, 196, 1);
  box-shadow: 0px 1px 1px 0px rgba(196, 196, 196, 1); */
  border-radius: 10px;

}

.containerHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.containerHeader .title {
  flex: 1;
  display: flex;
  align-items: center;
}



.containerHeader .title svg {
  margin-right: 20px;
}

/* CONTAINER TITLE */

.containerTitleBig {
  font-size: 1rem;
  font-weight: bold;
  color: var(--color-theme-blue1);

}


.containerTitleSmall {
  font-size: 1.8rem;
  font-weight: 400;
  color: #000;

}

.containerInsideTitle {
  font-size: 1.3rem;
  font-weight: 500;
  color: #000;
  font-family: Roboto;

}

.containerInsideTitleBig {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: Roboto;
  width: 80%;
  line-height: 20px;

}


/* content */

.filledContent {
  background-color: #FFF;
  padding: 20px;
  border: 1px solid var(--color-border);
  box-shadow: 0px 0px 1px 0px var(--color-border);
}




/* input */
.basicInput {
  padding: 8px 6px;
  border: 0px;
  font-size: 13px;
  outline: none;
  border: 1px solid var(--color-border);
  font-weight: normal;
  font-size: 1.4rem;
}

.basicInput2 {
  padding: 5px 4px;
  outline: none;
  border: 0px;
  font-weight: normal;
  font-size: 1.3rem;
  background-color: whitesmoke;
}


.stretchInput {
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 10px;
  border: 1px solid var(--color-border);
  font-size: 13px;
  outline: none;
  height: 20px;

}












/* button */

.iconBtn {
  background-color: transparent;
  border: 0px;
  outline: none;
  opacity: 0.6;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  padding: 3px;
}

.iconBtn:hover {
  background-color: transparent;
  border: 0px;
  outline: none;
  opacity: 1;
}



.blueBtn {
  background-color: var(--color-theme-blue3);
  font-weight: bold;
  color: #FFF;
  border: 0;
  transition-duration: 0.2s;
  padding: 8px 5px;
  outline: none;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blueBtn svg {
  margin-right: 5px;
}

.blueBtn:hover {
  background-color: rgb(24, 92, 170);
}

.whiteBtn {
  background-color: #FFF;
  font-weight: bold;
  color: #000;
  border: 1px solid #000;
  transition-duration: 0.2s;
  padding: 5px 4px;
  outline: none;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whiteBtn svg {
  margin-right: 5px;
}

.whiteBtn:hover {
  background-color: whitesmoke;
}


.whiteBtn2 {
  background-color: transparent;
  font-weight: bold;
  color: #FFF;
  border: 2px solid #FFF;
  transition-duration: 0.2s;
  padding: 5px 4px;
  outline: none;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whiteBtn2 svg {
  margin-right: 5px;
}

.whiteBtn2:hover {
  background-color: #FFF;
  color: var(--color-theme-gray1);
  border-color: transparent;
}


.blueBtn2 {
  border: 3px solid var(--color-theme-blue3);
  display: flex;
  align-items: center;
  color: var(--color-theme-blue3);
  background-color: transparent;
  padding: 5px 4px;
  font-weight: bold;
  width: 100%;
  justify-content: center;
  transition-duration: 0.2s;
  font-size: 1.2rem;
  justify-content: center;
  outline: none;
}

.blueBtn2 svg {
  margin-right: 5px;
}

.blueBtn2:hover {
  background-color: var(--color-theme-blue3);
  color: #FFF;
}

.btnLink {
  background-color: transparent;
  color: var(--color-theme-blue3);
  font-size: 1.3rem;
  border: 0;
  display: flex;
  align-items: center;
  transition-duration: 0.2s;
  font-weight: bold;
  outline: none;
  font-size: 1.2rem;

}

.btnLink svg {
  margin-right: 5px;
}

.btnLink:hover {
  color: rgb(24, 92, 170);
  text-decoration: underline;
}


.btn-link-red {
  background-color: transparent;
  color: rgb(235, 68, 68);
  font-size: 1.3rem;
  border: 0;
  display: flex;
  align-items: center;
  transition-duration: 0.2s;
  font-weight: bold;
  outline: none;
  font-size: 1.2rem;

}

.btn-link-red svg {
  margin-right: 5px;
}

.btn-link-red:hover {
  color: rgb(151, 41, 41);
  text-decoration: underline;
}

.black-btn {
  border: 2px solid #000;
  display: flex;
  align-items: center;
  color: #000;
  background-color: transparent;
  padding: 5px 4px;
  font-weight: bold;
  width: 100%;
  justify-content: center;
  transition-duration: 0.2s;
  font-size: 1.2rem;
  justify-content: center;
  outline: none;
}



.tabBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 0;
  transition-duration: 0.2s;
  font-weight: bold;
  flex: 1;
  background-color: #FFF;
  outline: none;
  font-size: 1.3rem;
}


.activeTab {

  background-color: #FFF;
  color: var(--color-theme-blue3);
  /* color:#000; */
  border-bottom: 3px solid var(--color-theme-blue3);
}




/* OVERLAY */

.overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  background-color: #000;
  opacity: 0.6;
  height: 100%;
  z-index: 4;
}



/* DETAILS */

.label {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: var(--color-text-label);
}

.whiteLabel {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #FFF;
}


/* page */

.pageContent {
  flex: 1;
  height: 100%;
  background-color: var(--color-background);
  padding: 20px 40px;
  margin-left: 60px;
  margin-top: 80px;
  display: flex;
}


/* loading */

#loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  font-size:1.6rem;
  font-weight: 500;
  flex-direction: column-reverse;
}

#loadingContainer img {
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
}



/* HELP */

#helpContainer {
  display: inline;
  position: relative;
}

#helpContainer svg {
  width: 16px;
  height: 16px;
}

#helpWindow {
  position: absolute;
  width: 275px;
  background-color: var(--color-theme-gray1);
  color: #FFF;
  padding: 15px;
  font-size: 1.3rem;
  line-height: 20px;
  text-align: left;
  word-wrap: wrap;
  top: 20px;
  z-index: 1;
}


/* EXPORT BTN */

#exportBtnContainer {
  position: relative;
  display: flex;
}

#exportOptions {
  position: absolute;
  background-color: #FFF;
  width: 250px;
  right: 0px;
  border: 1px solid var(--color-border);
  box-shadow: 0px 0px 0px 1px var(--color-border);
  top: -95px;


}

#exportOptions div {
  padding: 15px;
  font-size: 1.3rem;
  transition-duration: 0.2s;
  cursor: pointer;
}

#exportOptions div:hover {
  background-color: #F1F1F1;
}


#exportColumnsModal {
  position: fixed;
  left: 50%;
  width: 50vw;
  margin-left: -25vw;
  top: 30px;
}

#exportColumnsModal .columnNameSection {
  flex: 1;
}

#exportColumnsModal .columnNameSection input {
  border: 0;
  padding: 10px;
  width: 500px;
}

.fileFormatSection {
  display: flex;
  align-items: center;
  flex: 1;
}
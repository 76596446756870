#centauro-popup-container {
    width: 40vw;
    position: fixed;
    left: 50%;
    margin-left: -20vw;
    font-family: Roboto !important;
    top: 40px;

}

#centauro-popup-container .page{
    overflow-y:auto;
}

#centauro-popup-container #navigator-section {
    padding:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top:1px solid #e1e1e1;
    text-align: center;
}

#centauro-popup-container #navigator-section div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}


#centauro-popup-container section {
    font-size: 1.4rem;
    line-height: 25px;
    border-bottom: 1px solid #e1e1e1;
    padding: 40px;
}



#centauro-popup-container section .checkbox-option:last-child {
    margin-bottom: 0px;
}

#centauro-popup-container p {
    font-size: 1.6rem;
    line-height: 25px;
    text-align: justify;
    margin-bottom:20px;
    font-family: Roboto;
}

#centauro-popup-container .centered {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0px 0px;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}

#centauro-popup-container h1 {
    font-family: segoe ui;
    font-size: 3rem;
    margin-bottom: 20px;
}

#centauro-popup-container .first-page {
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#centauro-popup-container strong {
    /* color:rgb(61, 131, 210); */
}

#centauro-popup-container .question-title {
    margin-bottom: 20px;
}

.checkbox-options {
    display: flex;
    flex-direction: column;
}


.checkbox-option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.checkbox-option input {
    margin-right: 10px;
}

.input-option input {
    margin-top: 10px;
}

#centauro-popup-container textarea {
    resize: vertical;
    line-height: 25px;
    padding: 10px;
    font-family: Roboto;
    font-size: 1.4rem;
}

input:disabled{
    background-color: rgb(194, 194, 194);
}